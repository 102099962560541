import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";

function ProjectDetails() {
  const [resource, setResource] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const item = location.state?.item;

  //const skillName = item.projectSkills.map((item) => item.skillName);

  const fetchProject = async (item) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}projection/${item.id}`
      );
      if (response.status == 200) {
        setResource(response.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProject(item);
  }, []);


  const handleclickBack = () => {
    navigate('/project');
  }

  const monthsToYearsConvert = (value) => {
    const years = Math.floor(value / 12);
    const months = value % 12;
    return `${years} yrs ${months} months`;
  };

  //const totalResources = item?.projectSkills.reduce((total, skill) => total + skill.resources, 0);
  const totalAvailableResources = resource && resource.assignedProjections ? resource.assignedProjections.length : 0;
  {/* <IoMdArrowRoundBack className="text-lg" /> */ }
  return (
    <div className="mb-10">
      <div className="px-8 text-left mb-4"></div>
      <h2 className=" mb-8 font-bold text-xl text-left flex items-center gap-2 px-8"><span className=" gap-1 text-sm flex items-center" onClick={handleclickBack}></span>Project Details</h2>
      <div className="px-8 m-auto">
        <div className="relative overflow-x-auto border border-gray">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="border-b border-gray bg-blue text-white">
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Manger
                </th>
                <th scope="col" className="px-6 py-3">
                  Client
                </th>
                <th scope="col" className="px-6 py-3">
                  Location
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Start Date
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  End Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Duration
                </th>
                <th scope="col" className="px-6 py-3">
                  Owner
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4  text-gray-900 whitespace-nowrap dark:text-white">
                  {item.name}
                </td>
                <td className="px-6 py-4  text-gray-900 whitespace-nowrap dark:text-white">
                  {item.type}
                </td>
                <td className="px-6 py-4  text-gray-900 whitespace-nowrap dark:text-white">
                  {item.projectManager}
                </td>
                <td className="px-6 py-4  text-gray-900 whitespace-nowrap dark:text-white">
                  {item.clientName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <ul>
                    {item.locations?.map((location) => (
                      <li key={location.name}>{location.name}</li>
                    ))}
                  </ul>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {moment(item.startDate).format("YYYY-MM-DD")}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {moment(item.endDate).format("YYYY-MM-DD")}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{monthsToYearsConvert(item.timeDuration)}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.projectOwner}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {item?.siteDetails && (
          <>
            <h2 className="mt-8 mb-2 font-bold text-xl text-left">Description</h2>
            <div className="text-left"> {item?.projectDescription} </div>
          </>
        )}
        {item?.projectSkills && <h2 className="mt-8 mb-2 font-bold text-xl text-left">Skills Required</h2>}
        {item?.projectSkills && (
          <>
            <table className="border border-gray-300 mt-4">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr className="border-b border-gray-300 bg-blue text-white">
                  <th scope="col" className="px-6 py-3">Id</th>
                  <th scope="col" className="px-6 py-3">Name</th>
                  <th scope="col" className="px-6 py-3">Resources</th>
                </tr>
              </thead>
              <tbody>
                {item.projectSkills.map((skill) => (
                  <tr key={skill.skillId} className="border-b border-gray-200">
                    <td className="px-6 py-4">
                      S00{skill.skillId.toString().padStart(2, '0')}
                    </td>
                    <td className="px-6 py-4">
                      <span>{skill.skillName}</span>
                    </td>
                    <td className="px-6 py-4">
                      <span className="text-sm">{skill.resources}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        {item?.estimatedBudget && <h2 className="mt-8 mb-2 font-bold text-xl text-left">Budget Breakdown</h2>}
        {item?.estimatedBudget && (
          <>
            <table className="border border-gray-300 mt-4 w-full">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr className="border-b border-gray-300 bg-blue text-white">
                  <th scope="col" className="px-6 py-3">Labour Cost</th>
                  <th scope="col" className="px-6 py-3">Material Cost</th>
                  <th scope="col" className="px-6 py-3">Contingency Cost</th>
                  <th scope="col" className="px-6 py-3">Equipment Cost</th>
                  <th scope="col" className="px-6 py-3">Estimated Budget</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-gray-200">
                  <td className="px-6 py-4">£ {item.labourCost}</td>
                  <td className="px-6 py-4">£ {item.materialCost}</td>
                  <td className="px-6 py-4">£ {item.contingencyCost}</td>
                  <td className="px-6 py-4">£ {item.equipmentCost}</td>
                  <td className="px-6 py-4">£ {item.estimatedBudget}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        {resource && !resource?.assignedProjections && <h2 className="mt-8 mb-2 font-bold text-xl text-left">No Available Resource</h2>}
        {/* {totalResources} */}
        {resource?.assignedProjections && <> <h2 className="mt-8 mb-2 font-bold text-xl text-left">Available Resources ({totalAvailableResources})</h2>
          <table className="border border-gray mt-4 w-full">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="border-b border-gray bg-blue text-white">
                <th scope="col" className="px-6 py-3">
                  S.No.
                </th>
                <th scope="col" className="px-6 py-3">
                  Resource Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Resource Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Skills
                </th>
                <th scope="col" className="px-6 py-3">
                  shifts
                </th>
                <th scope="col" className="px-6 py-3">
                  Start Date
                </th>
                <th scope="col" className="px-6 py-3">
                  EndDate
                </th>
              </tr>
            </thead>
            <tbody>
              {resource?.assignedProjections?.map((item, index) => {
                return (
                  <tr className="border border-gray">
                    <td className="px-6 py-4">
                      {index + 1}
                    </td>
                    <td className="px-6 py-4">
                      R00{(item.resourceId).toString().padStart(2, '0')}
                    </td>
                    <td className="px-6 py-4">

                      <span className="text-sm">{item.resourceName}</span>
                    </td>
                    <td className="px-6 py-4">
                      <span>
                        <span className="text-sm">
                          {item.skills.map((skill) => skill.name).join(', ')}
                        </span>
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <span className="text-sm">{item.shiftName}</span>
                    </td>
                    <td className="px-6 py-4">
                      <span className="text-sm"> {moment(item?.projectionStartDate).format("YYYY-MM-DD")}</span>
                    </td>
                    <td className="px-6 py-4">
                      <span className="text-sm">{moment(item?.projectionEndDate).format("YYYY-MM-DD")}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
        }
      </div>
    </div>
  );
}

export default ProjectDetails;
