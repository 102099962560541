
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Resource from "../Resource";
import Skills from "../Skills";
import { Home } from "../Home";
import Shifts from "../Shifts";
import Project from "../Project";
import Login from "../Login";
import Manager from "../Manager";
import Location from "../Location";
import ProjectDetails from "../ProjectDetails";
import ProjectSave from "../Project/ProjectSave";
import ProjectType from "../ProjectType";
import AvailableResource from "../AvailableResource";


const AppRouter = () => {
    return <BrowserRouter>
        <Routes>

            <Route exact path="/" element={<Home />} >
                <Route exact path="/Skills" element={<Skills />} />
                <Route exact path="/" element={<Resource />} />
                <Route exact path="/resource" element={<Resource />} />
                <Route exact path="/shifts" element={<Shifts />} />
                <Route exact path="/manager" element={<Manager />} />
                <Route exact path="/location" element={<Location />} />
                <Route exact path="/project" element={<Project />} />
                <Route exact path="/projectdetails" element={<ProjectDetails />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/add-project" element={<ProjectSave />} />
                <Route exact path="/projectType" element={<ProjectType />} />
                <Route exact path="/availableResources" element={<AvailableResource />} />

            </Route>
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </BrowserRouter>
}


export default AppRouter;
