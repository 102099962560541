import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

function UploadProject({ setModal, editData, setLoader }) {
  const [user, setUser] = useState({
    projectUpload: "",
  });
  const [errors, setErrors] = useState({});
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false); // Track button loading state

  const validate = () => {
    const errors = {};
    if (!user.projectUpload) {
      errors.projectUpload = "File is required";
    }
    return errors;
  };

  const handelInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    const validationErrors = validate();
    setValidationMessage("");
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
    }
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      setLoading(true);
      uploadProjectData();
    } else {
      setErrors(validationErrors);
    }
  };

  const uploadProjectData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}projects/ImportProject`;
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append("file", user.projectUpload);
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        toast.success("Project uploaded successfully");
        setModal(false);
        window.location.reload(); // Reload the page
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "Error uploading the project.");
      setLoading(false);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>

      <h3 className="text-xl mb-4 font-bold text-left pb-2 border-b-2 border-gray">
        {!loading ? "Upload Project" : "Processing file..."}
      </h3>
      {!loading &&
        <div className="flex justify-between gap-5 align-baseline">
          <div className="w-full">
            <label
              htmlFor="projectUpload"
              className="block mb-2 pt-2 text-left text-sm font-medium"
            >
              Upload Project File
            </label>
            <input
              name="projectUpload"
              type="file"
              onChange={(e) => setUser({ ...user, projectUpload: e.target.files[0] })}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
            {errors.projectUpload && <p className="text-left text-red">{errors.projectUpload}</p>}
          </div>
        </div>}
      {!loading &&
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse mb-6">
          <span className="mt-8 flex w-full justify-center gap-4 rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              onClick={handelSubmit}
              disabled={loading} // Disable the button when loading
              className={`inline-flex justify-center w-1/3 rounded-md border border-gray-300 px-4 py-2 text-white leading-6 font-medium shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5 ${loading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue hover:bg-lightblue hover:text-black"
                }`}
            >
              Submit
              {/* {loading ? "Processing..." : editData ? "Update" : "Submit"} */}
            </button>
            <button
              type="button"
              onClick={() => setModal(false)}
              className="inline-flex justify-center w-1/3 rounded-md border border-gray-300 px-4 py-2 text-white bg-lightred leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Close
            </button>
          </span>
        </div>
      }

    </div>
  );
}

export default UploadProject;
