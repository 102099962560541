import { React, useEffect, useState } from 'react'
import { FaRegUser } from "react-icons/fa";
import { GoSignOut } from "react-icons/go";
import companyLogo from '../../../Asstes/logo.png'
function Header() {
  const handleLogOut = () => {
    localStorage.clear('loginUSer')
    window.location.href = '/home'
  }
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('loginUser'));
    if (items) {
      setItems(items);
      if (items.email) {
        const userName = items.email.split('@')[0];
        const formattedUserName = userName.charAt(0).toUpperCase() + userName.slice(1);
        setItems(formattedUserName);
      }
    }
  }, []);

  return (
    <div className='flex justify-between'>
      <div className="flex  items-center pr-4 w-full justify-end bg-blue">
        <div className="px-3 flex items-center w-full justify-between">
          <div className='w-[140px] pl-6'>
            <img src={companyLogo} alt="Tmotions logo" />
          </div>
          <div>
            <div className='flex gap-4 p-3 items-center text-white'>
              <GoSignOut onClick={() => handleLogOut()} className='cursor-pointer' />
              <span className='flex items-center gap-2'> <FaRegUser /> {items || "Admin"}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header