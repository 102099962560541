import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FaPlusCircle } from "react-icons/fa";
import { GrPowerReset, GrCheckmark } from "react-icons/gr";
import { getLocationData, getprojectTypeData, getSkillData, getManagerData } from "../../utils/api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

function ProjectSave() {
    const [userSkill, setUserSkill] = useState([]);
    const [locations, setLocations] = useState([]);
    const [showFiled, setShowFiled] = useState(false);
    const [loader, setLoader] = useState(false);
    const [errors, setErrors] = useState({});
    const [disbleDate, setDisbleDate] = useState();
    const [projectType, setProjectType] = useState([]);
    const [projectManager, setProjectManager] = useState([]);
    const [skillRows, setSkillRows] = useState([]);
    const [validationMessage, setValidationMessage] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [project, setProject] = useState({
        name: "",
        clientName: "",
        description: "",
        startDate: "",
        endDate: "",
        resources: "",
        year: "",
        month: "",
        locations: "",
        labourCost: "",
        equipmentCost: "",
        materialCost: "",
        contingencyCost: "",
        estimatedCost: "",
        projectManager: "",
        projectOwner: "",
        projectType: "",
        projectDescription: "",
        timeDuration: ""
    });

    const navigate = useNavigate();

    const [labourCost, setLabourCost] = useState(0);
    const [equipmentCost, setEquipmentCost] = useState(0);
    const [materialCost, setMaterialCost] = useState(0);
    const [contingencyCost, setContingencyCost] = useState(0);
    const [estimatedCost, setEstimatedCost] = useState(0);

    const handleLabourCostChange = (e) => {
        const value = parseFloat(e.target.value) || 0;
        setLabourCost(value);
        updateEstimatedBudget(value, equipmentCost, materialCost, contingencyCost);
    };

    const handleEquipmentCostChange = (e) => {
        const value = parseFloat(e.target.value) || 0;
        setEquipmentCost(value);
        updateEstimatedBudget(labourCost, value, materialCost, contingencyCost);
    };

    const handleMaterialCostChange = (e) => {
        const value = parseFloat(e.target.value) || 0;
        setMaterialCost(value);
        updateEstimatedBudget(labourCost, equipmentCost, value, contingencyCost);
    };

    const handleContingencyCostChange = (e) => {
        const value = parseFloat(e.target.value) || 0;
        setContingencyCost(value);
        updateEstimatedBudget(labourCost, equipmentCost, materialCost, value);
    };

    const updateEstimatedBudget = (labour, equipment, material, contingency) => {
        const total = labour + equipment + material + contingency;
        setEstimatedCost(total);
    };


    const validate = () => {
        const errors = {};
        if (!project.name && !project.skill &&
            !project.labourCost && !project.materialCost &&
            !project.equipmentCost && !project.projectType &&
            !project.projectManager && !project.clientName && !project.projectOwner
        ) {
            errors.name = "Name is required";
            errors.startDate = "Start Date is required";
            errors.endDate = "End Date is required";
            errors.labourCost = "Labour Cost is required";
            errors.materialCost = "Material Cost is required";
            errors.contingencyCost = "Contingency Costis required";
            errors.equipmentCost = "Equipment Cost is required";
            errors.projectManager = "Project Manager is required";
            errors.projectType = "Project Type is required";
            errors.clientName = "Client Name is required";
            errors.projectOwner = "Project Owner is required";
        }
        return errors;
    };

    async function fetchItems() {
        try {
            const skillData = await getSkillData();
            setUserSkill(skillData?.data || []);

            const locationData = await getLocationData();
            setLocations(locationData?.data || []);

            const typeData = await getprojectTypeData();
            setProjectType(typeData?.data || []);

            const managerData = await getManagerData();
            setProjectManager(managerData?.data || []);

        } catch (error) {
            console.error("Failed to fetch items:", error);
        }
    }

    useEffect(() => {
        fetchItems();
    }, []);

    const selecteskill = userSkill.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    });

    const locationsOpts = locations.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    });

    const typesOptions = projectType.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    });

    const managerOptions = projectManager.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    });

    const handleSelectChange = (selectedOptions, name) => {
        let valueToBeSet;
        if (name == 'locations') {
            valueToBeSet = [selectedOptions.value]
        }
        else if (name == 'projectType' || name == 'projectManager') {
            valueToBeSet = selectedOptions.label
        }
        setProject(prevState => ({
            ...prevState,
            [name]: valueToBeSet ? valueToBeSet : (Array.isArray(selectedOptions)
                ? selectedOptions.map(option => option.value)
                : selectedOptions.value),
        }));
    };

    const handelInput = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setProject({ ...project, [name]: value });
        const validationErrors = validate();
        setValidationMessage('')
        if (Object.keys(validationErrors).length === 0) {
            setErrors({});

        }
        if (project.startDate >= project.endDate) {
            setDisbleDate(true);
        }
    };

    const AddLocationData = async (e) => {
        if (!project.locations || project.locations.trim() === "") {
            setValidationMessage("Location field is required.");
            return;
        }

        setValidationMessage("");
        const url = `${process.env.REACT_APP_BASE_URL}WorkLocation/AddWorkLocation`;
        try {
            const response = await axios.post(url, {
                name: project.locations,
            });

            if (response.status === 200) {
                toast.success("Location Added Successfully");
                setProject((prev) => ({ ...prev, locations: "" }));
                setShowFiled(false);
                fetchItems();
                setLoader(true);
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to add location. Please try again.");
        }

    };

    const handelSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            setErrors({});
            AddProjectData();
        } else {
            setErrors(validationErrors);
        }
    };

    const convertToMonths = (year, month) => {
        const projectYear = isNaN(parseInt(year)) ? 0 : parseInt(year);
        const projectMonth = isNaN(parseInt(month)) ? 0 : parseInt(month);
        const totalMonths = (projectYear * 12) + projectMonth;
        return totalMonths;
    };

    const handleAddNewRow = (e) => {
        e.preventDefault();
        setSkillRows([...skillRows, { id: skillRows.length + 1, skillId: '', skillName: '', resources: '' }]);
    };

    const handleRemoveRow = (id) => {
        setSkillRows(skillRows.filter(row => row.id !== id));
    };

    const handleSelectSkillChange = (selected, index) => {
        const updatedRows = skillRows.map((row, i) => {
            if (i === index) {
                return { ...row, skillId: selected.value, skillName: selected.label };
            }
            return row;
        });
        setSkillRows(updatedRows);
    };

    const handleSkillInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedRows = skillRows.map((row, i) => {
            if (i === index) {
                return { ...row, [name]: parseInt(value) };
            }
            return row;
        });
        setSkillRows(updatedRows);
    };

    const AddProjectData = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}projects/AddProject`;
        try {
            const response = await axios.post(url, {
                name: project?.name,
                startDate: project?.startDate,
                endDate: project.endDate,
                timeDuration: convertToMonths(project?.year, project?.month),
                clientName: project?.clientName,
                type: project?.projectType,
                projectMilestone: "dummy test milestone",
                projectDescription: project?.projectDescription,
                projectManager: project?.projectManager,
                labourCost: labourCost ?? 0,
                materialCost: materialCost ?? 0,
                equipmentCost: equipmentCost ?? 0,
                contingencyCost: contingencyCost ?? 0,
                estimatedBudget: estimatedCost ?? 0,
                projectOwner: project?.projectOwner,
                siteDetails: "testing dummy test site details",
                locations: project?.locations,
                projectSkills: skillRows
            });

            if (response.status == 200) {
                toast.success(`Project Added Successfully`);
                setLoader(true);
                setProject([]);
                setTimeout(() => {
                    navigate('/project')
                }, 1000);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data.message);
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="flex justify-between items-center p-4 mb-5">
                <h2 className="font-bold text-xl">Add New Project</h2>
            </div>
            <div className="flex justify-content-between py-4 mx-4 my-4 gap-5 align-baseline">
                <div className="w-1/2">
                    <label for="first_name" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Name
                    </label>
                    <input
                        name="name"
                        type="text"
                        onChange={handelInput}
                        id="first_name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
                        placeholder="Project Name"
                        required
                    />
                    {errors.name && <p className="text-left text-red">{errors.name}</p>}
                </div>
                <div className="w-1/2">
                    <label for="first_name" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Start Date
                    </label>
                    <input
                        name="startDate"
                        className="bg-gray-50 border
                        border-gray-300 text-gray-900 text-sm rounded-sm
                        focus:ring-blue-500 focus:border-blue-500 block w-full
                        p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                        dark:text-white dark:focus:ring-blue-500
                        dark:focus:border-blue-500 border-gray"
                        type="date"
                        id="startDate"
                        onChange={handelInput}
                        placeholder="Project start date"
                        required
                    />
                    {errors.startDate && <p className="text-left text-red">{errors.startDate}</p>}
                </div>
                <div className="w-1/2">
                    <label for="first_name" className="block mb-2 pt-2 text-left text-sm font-medium">
                        End Date
                    </label>
                    <input
                        type="date"
                        disabled={!disbleDate}
                        min={project.startDate}
                        //value={project.endDate}
                        onChange={handelInput}
                        name="endDate"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray  "
                    />
                    {errors.endDate && <p className="text-left text-red">{errors.endDate}</p>}
                </div>
                <div className="w-1/2">
                    <label for="first_name" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Project Type
                    </label>
                    <Select
                        name="projectType"
                        options={typesOptions}
                        onChange={(selected) => handleSelectChange(selected, 'projectType')}
                        classNamePrefix="select Project type"
                        placeholder="Choose types"
                        styles={{
                            placeholder: (base) => ({
                                ...base,
                                textAlign: 'left',
                            }),
                        }}
                    />
                    {errors.projectType && <p className="text-left text-red">{errors.projectType}</p>}
                </div>
                <div className="w-1/2">
                    <label for="first_name" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Project Manager
                    </label>
                    <Select
                        name="projectManager"
                        options={managerOptions}
                        onChange={(selected) => handleSelectChange(selected, 'projectManager')}
                        classNamePrefix="select Project Manager"
                        placeholder="Choose manager"
                        styles={{
                            placeholder: (base) => ({
                                ...base,
                                textAlign: 'left',
                            }),
                        }}
                    />
                    {errors.projectManager && <p className="text-left text-red">{errors.projectManager}</p>}
                </div>
            </div>
            <div className="flex justify-content-between py-4 mx-4 my-4 gap-5 align-baseline">
                <div className="">
                    <label for="first_name" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Duration
                    </label>
                    <div className="flex gap-2">
                        <input
                            name="year"
                            onChange={handelInput}
                            type="number"
                            id="year"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
                            placeholder="Year"
                            required
                            min="0"
                            maxLength={3}
                        />
                        <input
                            name="month"
                            //value={project.months}
                            onChange={handelInput}
                            type="number"
                            id="month"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
                            placeholder="Months"
                            required
                            min="0"
                            max="12"
                        />
                    </div>
                </div>
                <div className="w-1/2">
                    <label for="first_name" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Client Name
                    </label>
                    <input
                        name="clientName"
                        type="text"
                        id="clientName"
                        onChange={handelInput}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
                        placeholder="client name"
                        required
                    />
                    {errors.clientName && <p className="text-left text-red">{errors.clientName}</p>}
                </div>
                <div className="w-1/2">
                    <label for="first_name" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Project Owner
                    </label>
                    <input
                        name="projectOwner"
                        type="text"
                        id="owner"
                        onChange={handelInput}
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
                        placeholder="owner name"
                        required
                    />
                    {errors.projectOwner && <p className="text-left text-red">{errors.projectOwner}</p>}
                </div>
                <div className="w-1/2">
                    <label for="first_name" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Project Location
                    </label>
                    <div className="flex items-center gap-1 justify-between">
                        <div className="w-full">
                            {!showFiled ? (
                                <Select
                                    name="locations"
                                    options={locationsOpts}
                                    onChange={(selected) => handleSelectChange(selected, 'locations')}
                                    className="basic-multi-select rounded-sm"
                                    classNamePrefix="select locations"
                                    placeholder="select location"
                                    styles={{
                                        placeholder: (base) => ({
                                            ...base,
                                            textAlign: 'left',
                                        }),
                                    }}
                                />
                            ) : (
                                <>
                                    <input
                                        name="locations"
                                        value={project.locations}
                                        onChange={handelInput}
                                        type="text"
                                        id="first_name"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
                                        placeholder="locations"
                                        required
                                    />
                                </>
                            )}
                            {validationMessage && (
                                <p className="text-red text-sm mt-1">
                                    {validationMessage}
                                </p>
                            )}
                        </div>
                        <div className="flex items-center gap-1">
                            {showFiled && (
                                <div className="relative group">
                                    <GrCheckmark
                                        className="text-xl text-green cursor-pointer"
                                        onClick={() => AddLocationData()}
                                    />
                                    <span className="absolute hidden group-hover:block bg-black text-white text-xs rounded-md px-2 py-1 top-6 left-1/2 transform -translate-x-1/2">
                                        Save
                                    </span>
                                </div>
                            )}
                            {!showFiled ? (
                                <div className="relative group">
                                    <FaPlusCircle
                                        className="text-xl text-green cursor-pointer"
                                        onClick={() => setShowFiled(true)}
                                    />
                                    <span className="absolute hidden group-hover:block bg-black text-white text-xs rounded-md px-2 py-1 top-4 left-1/2 transform -translate-x-1/2">
                                        Add Loction
                                    </span>
                                </div>
                            ) : (
                                <div className="relative group">
                                    <GrPowerReset
                                        className="text-xl"
                                        onClick={() => setShowFiled(false)}
                                    />
                                    <span className="absolute hidden group-hover:block bg-black text-white text-xs rounded-md px-2 py-1 top-6 left-1/2 transform -translate-x-1/2">
                                        Back
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-content-between py-4 mx-4 my-4 gap-5 align-baseline">
                <div className="w-1/2">
                    <label htmlFor="labourCost" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Labour Cost (£)
                    </label>
                    <input
                        name="labourCost"
                        type="number"
                        id="labourCost"
                        value={labourCost}
                        onChange={handleLabourCostChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Labour Cost"
                        required
                    />
                    {errors.labourCost && <p className="text-left text-red">{errors.labourCost}</p>}
                </div>
                <div className="w-1/2">
                    <label htmlFor="equipmentCost" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Equipment Cost (£)
                    </label>
                    <input
                        name="equipmentCost"
                        type="number"
                        id="equipmentCost"
                        value={equipmentCost}
                        onChange={handleEquipmentCostChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Equipment Cost"
                        required
                    />
                    {errors.equipmentCost && <p className="text-left text-red">{errors.equipmentCost}</p>}
                </div>
                <div className="w-1/2">
                    <label htmlFor="materialCost" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Material Cost (£)
                    </label>
                    <input
                        name="materialCost"
                        type="number"
                        id="materialCost"
                        value={materialCost}
                        onChange={handleMaterialCostChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Material Cost"
                        required
                    />
                    {errors.materialCost && <p className="text-left text-red">{errors.materialCost}</p>}
                </div>
                <div className="w-1/2">
                    <label htmlFor="contingencyCost" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Contingency Cost (£)
                    </label>
                    <input
                        name="contingencyCost"
                        type="number"
                        id="contingencyCost"
                        value={contingencyCost}
                        onChange={handleContingencyCostChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Contingency Cost"
                        required
                    />
                </div>
                <div className="w-1/2">
                    <label htmlFor="estimatedBudget" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Total budget (£)
                    </label>
                    <input
                        name="estimatedBudget"
                        type="number"
                        id="estimatedBudget"
                        value={estimatedCost}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Estimated Budget"
                        required
                        disabled
                    />
                </div>
            </div>
            <div className="flex justify-content-between py-4 mx-4 my-4 gap-5">
                <div className="w-full">
                    <label for="first_name" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Project Overview
                    </label>
                    <textarea
                        rows={5}
                        name="projectDescription"
                        onChange={handelInput}
                        id="projectDescription"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
                        placeholder="Project Overview">
                    </textarea>
                </div>
            </div>

            <div className="flex justify-content-between py-4 mx-4 my-4 gap-5 align-baseline">
                <div className="w-1/2">
                    <h4 htmlFor="skillTitle" className="block mb-2 pt-2 text-left text-sm font-medium">
                        Skills Required
                    </h4>
                </div>
                <div className="w-1/2 w-1/2 flex justify-end">
                    <button
                        type="button"
                        onClick={handleAddNewRow}
                        className="nline-flex justify-center  rounded-md border border-gray-300 px-4 py-2   hover:bg-lightblue hover:text-black text-white bg-blue leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        + Add Skills
                    </button>
                </div>
            </div>

            <div>
                {skillRows.map((row, index) => (
                    <div key={row.id} className="flex justify-between mx-4 py-4 gap-3">
                        <div className="w-1/2">
                            <label className="block mb-2 pt-2 text-left text-sm font-medium">
                                Skill {index + 1}
                            </label>
                            <Select
                                name="skills"
                                options={selecteskill}
                                className="basic-select rounded-sm mb-5"
                                classNamePrefix="select-skills"
                                onChange={(selected) => handleSelectSkillChange(selected, index)}
                                placeholder="select skill"
                                styles={{
                                    placeholder: (base) => ({
                                        ...base,
                                        textAlign: 'left',
                                    }),
                                }}
                            />
                        </div>
                        <div className="w-1/2">
                            <label className="block mb-2 pt-2 text-left text-sm font-medium">
                                No. of Resources
                            </label>
                            <input
                                name="resources"
                                type="number"
                                value={row.resources}
                                onChange={(e) => handleSkillInputChange(e, index)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="select no. of resources"
                                required
                            />
                        </div>
                        <div className="w-1/4 flex justify-between py-4 mx-4 my-4">
                            <button
                                type="button"
                                onClick={() => handleRemoveRow(row.id)}
                                className="py-2.5 px-4 text-sm font-semibold tracking-wider rounded-md text-white bg-lightred hover:bg-lightred-700 focus:outline-none"
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mt-5 mb-5">
                <span className="mt-8 flex w-full justify-center gap-4 rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                        type="button"
                        onClick={(e) => handelSubmit(e)}
                        className="inline-flex justify-center  rounded-md border border-gray-300 px-4 py-2   hover:bg-lightblue hover:text-black text-white bg-blue leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        {'Submit'}
                    </button>
                    <button
                        type="button"
                        className="inline-flex justify-center  rounded-md border border-gray-300 px-4 py-2  text-white bg-lightred leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                        Cancel
                    </button>
                </span>
            </div>
        </>
    )
}

export default ProjectSave;
