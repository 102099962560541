import React, { useState, useEffect } from "react";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Modal from "../Atom/Modal";
import { ToastContainer, toast } from "react-toastify";
import AddResource from "./AddResource";
import ConfirmationModal from "../Atom/ConfirmationModal";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Spinners from "../Atom/Spinners";
import ReactPaginate from "../Atom/Paginations";
import Pagination from "../Atom/Paginations";
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { getEveryFirstWordCaps } from "../../utils/features";
function Resource() {
  const [modal, setModal] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [EditUser, setEditUser] = useState(false);
  const [loader, setLoader] = useState(true);
  const [resourceData, setResourceData] = useState([]);
  const [resourceId, setResourceId] = useState([]);
  const [editData, setEditData] = useState({})
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    const pageParam = searchParams.get('page');
    return pageParam ? Number(pageParam) : 1;
  });
  const [count, setCount] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [path, setPath] = useState('');

  const HandleModal = () => {
    setModal(true);
    setDeleteUser(false);
    setEditData('')
  };
  const HandleClickDelete = (id) => {
    setDeleteUser(true);
    setResourceId(id)

  };

  const handleClickEdit = (item) => {
    setEditUser(true);
    setDeleteUser(false);
    setModal(true);
    setEditData(item)
  };

  const fetchResource = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}resources?pageIndex=${page}&pageSize=${pageSize}`
      );


      if (response.status == 200) {

        setResourceData(response?.data?.data);
        setLoader(false);
        setCount(response?.data?.pagination?.totalCount);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const DeleteResource = async (Id) => {

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}resources/${Id}`);
      if (response.status == 200) {
        toast.success('resources Deleted Successfully');
        setDeleteUser(false)
        setModal(false)
        setLoader(true)

      }
    } catch (error) {
      console.log(error);
      toast.error('Allready Deleted')
    }
  };

  const ConfirmDelete = () => {
    DeleteResource(resourceId)
  }

  useEffect(() => {
    if (loader || page) {
      fetchResource();

    }
  }, [loader, page]);

  const navigate = useNavigate();

  const handleClickPginate = (selectedPage) => {// Pass query parametersnavigate('/path?param1=value1&param2=value2');
    const newPath = `${window.location.pathname}?page=${selectedPage}`
    navigate(newPath);
    setPath(newPath);
    setLoader(true)

  }




  return (
    <div>{loader && <div className="flex justify-center items-center h-[100vh]">
      <Spinners
      />
    </div>}
      {!loader && <div>
        <div className="flex justify-between items-center p-4">
          <h2 className="font-bold text-xl">Resources</h2>
          <button
            className="bg-blue p-3 text-white font-semibold  rounded-lg hover:bg-lightblue hover:text-black"
            onClick={() => HandleModal()}
          >
            Add Resource
          </button>
        </div>
        <div className="p-4">
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-gray border">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr className="bg-lightblue">
                  <th scope="col" className="px-6 py-3 text-sm">
                    S.No.
                  </th>
                  <th scope="col" className="px-6 py-3 text-sm">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-sm">
                    Manager
                  </th>
                  <th scope="col" className="px-6 py-3 text-sm">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3 text-sm">
                    Skills
                  </th>
                  <th scope="col" className="px-6 py-3 text-sm">
                    Contact No.
                  </th>
                  <th scope="col" className="px-6 py-3 text-sm">
                    Shift
                  </th>
                  {/* <th scope="col" className="px-6 py-3 text-sm">
                    Availability
                  </th> */}
                  <th scope="col" className="px-6 py-3 text-sm text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {resourceData.map((item, index) => (
                  <tr
                    key={index}
                    className="bg-white  border-gray border border-b  boder-gray"
                  >
                    <th
                      scope="row"
                      className="text-xs px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {/* {index+1} */}
                      R00{(item.id).toString().padStart(2, '0')}
                    </th>

                    <td className="px-6 py-4 text-xs">{getEveryFirstWordCaps(item.name)}</td>
                    <td className="px-6 py-4 text-xs">{item.managerName}</td>
                    <td className="px-6 py-4 text-xs">{item.email}</td>
                    <td className="px-6 py-4 text-xs"> {item.skills?.map(skill => skill.name).join(', ')}</td>
                    <td className="px-6 py-4 text-xs">{item.phoneNumber}</td>
                    <td className="px-6 py-4 text-xs">{item.shiftName}</td>
                    {/* <td className="px-6 py-4 text-xs">{item.availabilityDays.map(day => day.name).join(', ')}</td> */}
                    <td className="px-6 py-4">
                      <div className="flex gap-3 items-center justify-center">
                        <div className="relative group">
                          <FaEdit
                            onClick={() => handleClickEdit(item)}
                            className="text-2xl text-blue cursor-pointer"
                          />
                          <span className="absolute hidden group-hover:block bg-black text-white text-xs rounded-md px-2 py-1 -top-8 left-1/2 transform -translate-x-1/2">
                            Edit
                          </span>
                        </div>
                        <div className="relative group">
                          <MdDeleteForever
                            onClick={() => HandleClickDelete(item.id)}
                            title="Delete"
                            className="text-2xl text-blue cursor-pointer"
                          />
                          <span className="absolute hidden group-hover:block bg-black text-white text-xs rounded-md px-2 py-1 -top-8 left-1/2 transform -translate-x-1/2">
                            Delete
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      }

      {modal && (
        <Modal setModal={setModal}>
          <AddResource
            modal={modal}
            deleteUser={deleteUser}
            setModal={setModal}
            editData={editData}
            setLoader={setLoader}
          />
        </Modal>
      )}
      <ToastContainer />
      {deleteUser && (
        <ConfirmationModal CloseConfimationsModal={setDeleteUser} ConfirmDelete={ConfirmDelete} />
      )}
      {count > 10 && <Pagination handleClickPginate={handleClickPginate} page={page} totalItems={count} setPage={setPage} itemsPerPage={pageSize} />}
    </div>
  );
}

export default Resource;
