import React from 'react'
import { useLocation } from 'react-router-dom';

function SideBar() {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className='py-2'>
      <div>
        <ul className="text-left">
          <li className={`p-3 pl-0 text-lg font-bold border-b border-b-blue ${pathname.includes('/resource') ? 'bg-blue text-white' : ''}`}>
            <a href="/resource" className={`text-xl py-3 pl-6 ${pathname.includes('/resource') ? 'text-white' : ''}`}>Resource</a>
          </li>
          <li className={`p-3 pl-0 text-lg font-bold border-b border-b-blue ${pathname === '/project' ? 'bg-blue text-white' : ''}`}>
            <a href="/project" className={`text-xl py-3 pl-6 ${pathname === '/project' ? 'text-white' : ''}`}>Project</a>
          </li>
          <li className={`p-3 pl-0 text-lg font-bold border-b border-b-blue ${pathname.includes('/skills') ? 'bg-blue text-white' : ''}`}>
            <a href="/skills" className={`text-xl py-3 pl-6 ${pathname.includes('/skills') ? 'text-white' : ''}`}>Skills</a>
          </li>
          <li className={`p-3 pl-0 text-lg font-bold border-b border-b-blue ${pathname.includes('/shifts') ? 'bg-blue text-white' : ''}`}>
            <a href="/shifts" className={`text-xl py-3 pl-6 ${pathname.includes('/shifts') ? 'text-white' : ''}`}>Shift</a>
          </li>
          <li className={`p-3 pl-0 text-lg font-bold border-b border-b-blue ${pathname.includes('/manager') ? 'bg-blue text-white' : ''}`}>
            <a href="/manager" className={`text-xl py-3 pl-6 ${pathname.includes('/manager') ? 'text-white' : ''}`}>Manager</a>
          </li>
          <li className={`p-3 pl-0 text-lg font-bold border-b border-b-blue ${pathname.includes('/location') ? 'bg-blue text-white' : ''}`}>
            <a href="/location" className={`text-xl py-3 pl-6 ${pathname.includes('/location') ? 'text-white' : ''}`}>Location</a>
          </li>
          <li className={`p-3 pl-0 text-lg font-bold border-b border-b-blue ${pathname === '/projectType' ? 'bg-blue text-white' : ''}`}>
            <a href="/projectType" className={`text-xl py-3 pl-6 ${pathname === '/projectType' ? 'text-white' : ''}`}>Project Type</a>
          </li>
          <li className={`p-3 pl-0 text-lg font-bold border-b border-b-blue ${pathname === '/availableResources' ? 'bg-blue text-white' : ''}`}>
            <a href="/availableResources" className={`text-xl py-3 ${pathname === '/availableResources' ? 'text-white' : ''}`}>Resource Available</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SideBar