import React, { useState, useEffect } from "react";
import { FaUserEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Modal from "../Atom/Modal";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationModal from "../Atom/ConfirmationModal";
import axios from "axios";
import Spinners from "../Atom/Spinners";
import moment from 'moment';
import AddManger from "./AddManager";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pagination from "../Atom/Paginations";
import { getEveryFirstWordCaps } from "../../utils/features";
function Manager() {
  const [modal, setModal] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [projectData, setProjectData] = useState(false);
  const [projectID, setProjectID] = useState([])
  const [loader, setLoader] = useState(true);
  const [editData, setEditData] = useState({})
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    const pageParam = searchParams.get('page');
    return pageParam ? Number(pageParam) : 1;
  });
  const [count, setCount] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [path, setPath] = useState('');
  const HandleModal = () => {
    setModal(true);
    setDeleteUser(false);
    setEditData('')
  };
  const HandleClickDelete = (id) => {
    setDeleteUser(true);
    setProjectID(id);
  };
  const handleClickEdit = (item) => {
    setDeleteUser(false);
    setModal(true);
    setEditData(item)
  };

  const navigate = useNavigate();

  const fetchManager = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}managers?pageIndex=${page}&pageSize=${pageSize}`
      );
      if (response.status == 200) {
        setProjectData(response.data.data);
        setLoader(false);
        setCount(response?.data?.pagination?.totalCount);

      }
    } catch (error) {
      console.log(error);
    }
  };




  const DeleteProject = async (id) => {

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}managers/${id}`);
      if (response.status == 200) {
        toast.success('Manager Deleted Successfully');
        setDeleteUser(false)
        setModal(false)
        setLoader(true)

      }
    } catch (error) {
      console.log(error);
      toast.error('Allready Deleted')
    }
  };

  const ConfirmDelete = () => {
    DeleteProject(projectID)
  }

  useEffect(() => {
    if (loader || page) {
      fetchManager();
    }
  }, [loader, page]);

  const handleClickPginate = (selectedPage) => {// Pass query parametersnavigate('/path?param1=value1&param2=value2');
    const newPath = `${window.location.pathname}?page=${selectedPage}`
    navigate(newPath);
    setPath(newPath);
    setLoader(true)

  }

  return (
    <div>
      {loader && (
        <div className="flex justify-center items-center h-[100vh]">
          <Spinners />
        </div>
      )}
      {!loader && (
        <>
          <div className="flex justify-between items-center p-4">
            <h2 className="font-bold text-xl">Manager</h2>
            <button
              className="bg-blue p-3 text-white font-semibold  rounded-lg hover:bg-lightblue hover:text-black"
              onClick={() => HandleModal()}
            >
              Add Manager
            </button>
          </div>
          <div className="p-4">
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-gray border">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr className="bg-lightblue">
                    <th scope="col" className="px-6 py-3 text-sm">
                      S.No.
                    </th>
                    {/* <th scope="col" className="px-6 py-3 text-sm">
                      ID
                    </th> */}
                    <th scope="col" className="px-6 py-3 text-sm">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-sm">
                      Created On
                    </th>
                    <th scope="col" className="px-6 py-3 text-sm">
                      Updated On
                    </th>
                    <th scope="col" className="px-6 py-3 text-sm">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projectData?.map((item, index) => (
                    <tr
                      className="bg-white border-gray border border-b border-gray"
                      key={item.id}
                    >
                      <th
                        scope="row"
                        className="text-xs px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {/* {index+1} */}
                        M00{(item.id).toString().padStart(2, '0')}
                      </th>
                      {/* <th
                        scope="row"
                        className="text-xs px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item.id}
                      </th> */}
                      <td className="px-6 py-4 text-xs">{getEveryFirstWordCaps(item.name)}</td>
                      <td className="px-6 py-4">{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                      <td className="px-6 py-4">{moment(item.updatedAt).format('YYYY-MM-DD')}</td>
                      <td className="px-6 py-4">
                        <div className="flex gap-3 items-center">
                          <div className="relative group">
                            <FaEdit
                              onClick={() => handleClickEdit(item)}
                              className="text-2xl text-blue cursor-pointer"
                            />
                            <span className="absolute hidden group-hover:block bg-black text-white text-xs rounded-md px-2 py-1 -top-8 left-1/2 transform -translate-x-1/2">
                              Edit
                            </span>
                          </div>
                          <div className="relative group">
                            <MdDeleteForever
                              onClick={() => HandleClickDelete(item.id)}
                              title="Delete"
                              className="text-2xl text-blue cursor-pointer"
                            />
                            <span className="absolute hidden group-hover:block bg-black text-white text-xs rounded-md px-2 py-1 -top-8 left-1/2 transform -translate-x-1/2">
                              Delete
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      {modal && (
        <Modal setModal={setModal}>
          {" "}
          <AddManger modal={modal} editData={editData} setLoader={setLoader} setModal={setModal} />
        </Modal>
      )}
      {deleteUser && (
        <ConfirmationModal CloseConfimationsModal={setDeleteUser} ConfirmDelete={ConfirmDelete} />
      )}
      <ToastContainer />
      {count > 10 && <Pagination handleClickPginate={handleClickPginate} page={page} totalItems={count} setPage={setPage} itemsPerPage={pageSize} />}
    </div>
  );
}

export default Manager;
