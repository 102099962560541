import React, { useEffect, useState } from "react";
import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function Login() {
  let navigate = useNavigate();

  const [loginUser, setLoginUser] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  const handlLoginInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setLoginUser({ ...loginUser, [name]: value });
    setErrors({
      ...errors,
      [name]: '', // Clear the error for the specific input field
    });
  };

  const validate = () => {
    const errors = {};

    // Email validation
    if (!loginUser.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(loginUser.email)) {
      errors.email = "Email is invalid";
    }

    // Password validation
    if (!loginUser.password) {
      errors.password = "Password is required";
    } else if (loginUser.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }

    return errors;
  };

  const handleLogin = () => {
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      // No validation errors, proceed with login
      localStorage.setItem("loginUser", JSON.stringify(loginUser));
      window.location.href = "/resource";
    }
  };
  // useEffect(() => {
  //     const storedUser = localStorage.getItem('loginUser');
  //     if (storedUser) {
  //         setUser(JSON.parse(storedUser));

  //     }
  // }, []);

  return (
    <div>
      <div className="flex justify-center items-center bg-gray-100 font-[sans-serif] h-full md:min-h-screen p-4">
        <div className="grid  max-w-[400px] mx-auto w-full">
          <div className="flex items-center h-[100vh]">
            <form className="bg-white rounded-2xl w-full p-6 -mt-24 relative z-10 shadow-[0_2px_16px_-3px_rgba(6,81,237,0.3)]">
              <div className="mb-12">
                <h3 className="text-3xl font-extrabold text-blue-600">Sign in</h3>
              </div>

              <div className="relative flex items-center">
                <input
                  name="email"
                  pattern=".+@example\.com"
                  type="email"
                  onChange={(e) => handlLoginInput(e)}
                  required
                  value={loginUser.email}
                  className="w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 px-2 py-3 outline-none"
                  placeholder="Enter email"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#bbb"
                  stroke="#bbb"
                  className="w-[18px] h-[18px] absolute right-2"
                  viewBox="0 0 682.667 682.667"
                >
                  <defs>
                    <clipPath id="a" clipPathUnits="userSpaceOnUse">
                      <path d="M0 512h512V0H0Z" data-original="#000000"></path>
                    </clipPath>
                  </defs>
                  <g
                    clip-path="url(#a)"
                    transform="matrix(1.33 0 0 -1.33 0 682.667)"
                  >
                    <path
                      fill="none"
                      stroke-miterlimit="10"
                      stroke-width="40"
                      d="M452 444H60c-22.091 0-40-17.909-40-40v-39.446l212.127-157.782c14.17-10.54 33.576-10.54 47.746 0L492 364.554V404c0 22.091-17.909 40-40 40Z"
                      data-original="#000000"
                    ></path>
                    <path
                      d="M472 274.9V107.999c0-11.027-8.972-20-20-20H60c-11.028 0-20 8.973-20 20V274.9L0 304.652V107.999c0-33.084 26.916-60 60-60h392c33.084 0 60 26.916 60 60v196.653Z"
                      data-original="#000000"
                    ></path>
                  </g>
                </svg>
              </div>
              {errors.email && <p className="text-lightred text-left text-sm">{errors.email}</p>}
              <div className="mt-6">
                <div className="relative flex items-center">
                  <input
                    name="password"
                    type="password"
                    onChange={(e) => handlLoginInput(e)}
                    required
                    value={loginUser.password}
                    className="w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 px-2 py-3 outline-none"
                    placeholder="Enter password"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#bbb"
                    stroke="#bbb"
                    className="w-[18px] h-[18px] absolute right-2 cursor-pointer"
                    viewBox="0 0 128 128"
                  >
                    <path
                      d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                      data-original="#000000"
                    ></path>
                  </svg>
                </div>
              </div>
              {errors.password && <p className="text-lightred text-left text-sm">{errors.password}</p>}
              <div className="flex flex-wrap items-center justify-between gap-4 mt-6">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 shrink-0 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label
                    for="remember-me"
                    className="text-gray-800 ml-3 block text-sm"
                  >
                    Remember me
                  </label>
                </div>
                <div>
                  <a
                    href="jajvascript:void(0);"
                    className="text-blue-600 text-sm font-semibold hover:underline"
                  >
                    Forgot Password?
                  </a>
                </div>
              </div>

              <div className="mt-12">
                <button
                  onClick={() => handleLogin()}
                  type="button"
                  className="w-full bg-blue py-2.5 px-4 text-sm font-semibold tracking-wider rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
                >
                  Sign in
                </button>
                <p className="text-sm text-center mt-6">
                  Don't have an account{" "}
                  <a
                    href="javascript:void(0);"
                    className="text-blue-600 font-semibold hover:underline ml-1 whitespace-nowrap"
                  >
                    Register here
                  </a>
                </p>
              </div>

              <hr className="my-6 border-gray-300" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
