import React, { useEffect, useState } from 'react'
import { FaUserEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Modal from '../Atom/Modal';
import { ToastContainer, toast } from 'react-toastify';
import { toastify } from '../Atom/Toastify'
import AddSkill from './AddSkill';
import ConfirmationModal from '../Atom/ConfirmationModal';
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import Spinners from '../Atom/Spinners';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pagination from '../Atom/Paginations';
import { getEveryFirstWordCaps } from '../../utils/features'
function Skills() {
  const [modal, setModal] = useState(false)
  const [deleteUser, setDeleteUser] = useState(false);
  const [skills, setSkills] = useState([]);
  const [skillId, setSkillId] = useState([]);
  const [loader, setLoader] = useState(true)
  const [editData, setEditData] = useState({})
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    const pageParam = searchParams.get('page');
    return pageParam ? Number(pageParam) : 1;
  });
  const [count, setCount] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [path, setPath] = useState('');
  const navigate = useNavigate();
  const HandleModal = () => {
    setModal(true)
    setDeleteUser(false);
    setEditData('')

  }
  const HandleClickDelete = (id) => {
    setSkillId(id)
    setDeleteUser(true)
  }
  const handleClickEdit = (item) => {
    setEditData(item)
    setDeleteUser(false)
    setModal(true)

  }

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}skills?pageIndex=${page}&pageSize=${pageSize}`
      );
      if (response.status == 200) {
        setSkills(response?.data?.data);
        setCount(response?.data?.pagination?.totalCount);
        setLoader(false)
      }

    } catch (error) {
      console.log(error);
    }
  };

  const DeleteSkills = async (id) => {

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}skills/${id}`);
      if (response.status == 200) {
        toast.success('Skill Deleted Successfully');
        setDeleteUser(false)
        setModal(false)
        setLoader(true)

      }
    } catch (error) {
      console.log(error);
      toast.error('Allready Deleted')
    }
  };
  const ConfirmDelete = () => {
    DeleteSkills(skillId)
  }


  useEffect(() => {
    if (loader || page) {
      fetchData()
    }
  }, [loader, page]);

  const handleClickPginate = (selectedPage) => {// Pass query parametersnavigate('/path?param1=value1&param2=value2');
    const newPath = `${window.location.pathname}?page=${selectedPage}`
    navigate(newPath);
    setPath(newPath);
    setLoader(true)
  }

  return (
    <div>
      {loader && <div className="flex justify-center items-center h-[100vh]">
        <Spinners />
      </div>}


      <div className='flex justify-between items-center p-4'>
        <h2 className='font-bold text-xl'>Skills</h2>
        <button className='bg-blue p-3 text-white font-semibold  rounded-lg hover:bg-lightblue hover:text-black' onClick={() => HandleModal()}>Add Skill</button>
      </div>
      <div className='p-4'>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-gray border">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className='bg-lightblue'>
                <th scope="col" className="px-6 py-3 text-sm">
                  S.No.
                </th>
                {/* <th scope="col" className="px-6 py-3 text-sm">
                  Skill ID
                </th> */}
                <th scope="col" className="px-6 py-3 text-sm">
                  Skills
                </th>
                <th scope="col" className="px-6 py-3 text-sm">
                  Created On
                </th>
                <th scope="col" className="px-6 py-3 text-sm">
                  Updated On
                </th>
                <th scope="col" className="px-6 py-3 text-sm text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>

              {skills?.map((item, index) => {
                const startDate = new Date(item.createdOn).toISOString().slice(0, 10);
                const endDate = new Date(item.updatedOn).toISOString().slice(0, 10);
                return (
                  <tr key={item.id} className="bg-white  border-gray border border-b  boder-gray">
                    <th scope="row" className="text-xs px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {/* //{index+1} */}
                      S00{(item.id).toString().padStart(2, '0')}
                    </th>
                    {/* <th scope="row" className="text-xs px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.id}
                    </th> */}
                    <td className="px-6 py-4 text-xs">
                      {getEveryFirstWordCaps(item.name)}
                    </td>
                    <td className="px-6 py-4 text-xs">
                      {item.createdOn ? startDate : '--'}
                    </td>
                    <td className="px-6 py-4 text-xs">
                      {item.updatedOn ? endDate : '--'}
                    </td>
                    <td className="px-6 py-4 text-center">
                      <div className='flex gap-3 items-center justify-center'>
                        <div className="relative group">
                          <FaEdit onClick={() => handleClickEdit(item)} className="text-2xl text-blue cursor-pointer" />
                          <span className="absolute hidden group-hover:block bg-black text-white text-xs rounded-md px-2 py-1 -top-8 left-1/2 transform -translate-x-1/2">
                            Edit
                          </span>
                        </div>
                        <div className="relative group">
                          <MdDeleteForever onClick={() => HandleClickDelete(item.id)} title='Delete' className='text-2xl text-blue cursor-pointer' />
                          <span className="absolute hidden group-hover:block bg-black text-white text-xs rounded-md px-2 py-1 -top-8 left-1/2 transform -translate-x-1/2">
                            Delete
                          </span>
                        </div>

                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      {modal && <Modal setModal={setModal}>
        <AddSkill setModal={setModal} setLoader={setLoader} editData={editData} />
      </Modal>}
      {deleteUser && <ConfirmationModal skillId={skillId} CloseConfimationsModal={setDeleteUser} ConfirmDelete={ConfirmDelete} />}
      <ToastContainer />
      {count > 10 && <Pagination handleClickPginate={handleClickPginate} page={page} totalItems={count} setPage={setPage} itemsPerPage={pageSize} />}
    </div>
  )
}

export default Skills